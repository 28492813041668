.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown .dropdown-content {
  background: #2f2f32;
  border-radius: 2px;
  margin-top: 12px;
  padding: 12px 8px;
  display: none;
  position: absolute;
  overflow: auto;
}

.dropdown .dropdown-content a {
  white-space: nowrap;
  border-radius: 2px;
  padding: 8px 20px;
  display: block;
}

.dropdown .dropdown-content #option1:hover, .dropdown .dropdown-content #option2:hover, .dropdown .dropdown-content #option3:hover {
  color: #1d1f24;
  background-color: #fcd3af;
}

.closed .dropdown-button .chevron-icon {
  background: url("chevron-up.04432d0c.svg");
}

.closed .dropdown-content {
  display: block;
}

/*# sourceMappingURL=index.e4eacf68.css.map */
