@import 'common/variables.scss';
@import 'common/mixins.scss';


.dropdown {
  display: inline-block;
  position: relative;

  .dropdown-content {
    display: none;
    margin-top: 12px;
    position: absolute;
    overflow: auto;
    padding: 12px 8px;
    background: $bg_lighter;
    border-radius: 2px;

    a {
      padding: 8px 20px;
      display: block;
      white-space: nowrap;
      border-radius: 2px;

    }

    #option1:hover, #option2:hover, #option3:hover {
      color: $bg;
      background-color: $primary;
    }
  }
}


.closed {
  .dropdown-button {
    .chevron-icon {
      background: url(../assets/chevron-up.svg);
    }
  }

  .dropdown-content {
    display: block;
  }
}



